import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

const TableChequesFisicos = ({ userId }) => {
  const [data, setData] = useState([]);
  const [statusFilter, setStatusFilter] = useState('EN CARTERA');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    const fetchChequesData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`https://patron.com.ar:5000/api/cheques?userId=${userId}`);
        if (!response.ok) {
          throw new Error('Error al obtener los datos de cheques');
        }
        const cheques = await response.json();

        // Ordenar cheques por fecha de cobro
        cheques.sort((a, b) => {
          const dateA = new Date(a.fechacobro);
          const dateB = new Date(b.fechacobro);
          return dateA - dateB;
        });

        setData(cheques);
      } catch (error) {
        setError(error);
        console.error('Error al obtener los datos de cheques:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchChequesData();
    }
  }, [userId]);

  const handleEstadoChange = async (id, newStatus) => {
    const updatedData = data.map((item) => (item.id === id ? { ...item, estado: newStatus } : item));
    setData(updatedData);

    try {
      const response = await fetch(`https://patron.com.ar:5000/api/cheques/${id}?userId=${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ estado: newStatus }),
      });
      if (!response.ok) {
        throw new Error('Error al actualizar el estado en la base de datos');
      }
    } catch (error) {
      console.error('Error al actualizar el estado en la base de datos:', error);
    }
  };

  const handleEntregadoChange = async (id) => {
    const newEntregado = inputValues[id] || '';
    const updatedData = data.map((item) => (item.id === id ? { ...item, entrega: newEntregado } : item));
    setData(updatedData);

    try {
      const response = await fetch(`https://patron.com.ar:5000/api/cheques/${id}?userId=${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ entrega: newEntregado }),
      });
      if (!response.ok) {
        throw new Error('Error al actualizar el entregado en la base de datos');
      }
      Swal.fire({
        title: 'Modificacion Exitosa!',
        text: 'Cheque Modificado con Exito.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      console.error('Error al actualizar el entregado en la base de datos:', error);
    }
  };

  const handleInputChange = (id, value) => {
    setInputValues({ ...inputValues, [id]: value });
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      (statusFilter === '' || item.estado === statusFilter) &&
      (item.nrocheque.toString().includes(searchTerm) ||
        item.cliente.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.banco.toString().includes(searchTerm) ||
        item.fechacobro.includes(searchTerm) ||
        item.monto.toString().includes(searchTerm) ||
        item.librador.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.cuitlibrador.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  if (loading) return <p>Cargando datos...</p>;
  if (error) return <p>Error al cargar los datos: {error.message}</p>;

  return (
    <div>
      <div className="mb-3">
        <input
          type="text"
          placeholder="Buscar..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-control mb-3"
        />

        <select className="form-select me-2" onChange={handleStatusChange} value={statusFilter}>
          <option value="">Filtrar por Estado</option>
          <option value="EN CARTERA">EN CARTERA</option>
          <option value="ENTREGADO">ENTREGADO</option>
        </select>
      </div>

      <table className="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th className="d-none d-md-table-cell">Fecha</th>
            <th>Cliente</th>
            <th>Monto</th>
            <th className="d-none d-md-table-cell">Banco</th>
            <th>Nro Cheque</th>
            <th className="d-none d-md-table-cell">Librador</th>
            <th className="d-none d-md-table-cell">Cuit Librador</th>
            <th>Fecha de Cobro</th>
            <th>Estado</th>
            <th>Entrega</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item) => (
            <tr key={item.id}>
              <td className="d-none d-md-table-cell">{item.fecha}</td>
              <td>{item.cliente}</td>
              <td>{Number(item.monto).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</td>
              <td className="d-none d-md-table-cell">{item.banco}</td>
              <td>{item.nrocheque}</td>
              <td className="d-none d-md-table-cell">{item.librador}</td>
              <td className="d-none d-md-table-cell">{item.cuitlibrador}</td>
              <td>{item.fechacobro}</td>
              <td>
                <select
                  className="form-select"
                  value={item.estado}
                  onChange={(e) => handleEstadoChange(item.id, e.target.value)}
                >
                  <option value="EN CARTERA">EN CARTERA</option>
                  <option value="ENTREGADO">ENTREGADO</option>
                </select>
              </td>
              <td>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nombre a quien se entregó"
                    value={inputValues[item.id] || item.entrega || ''}
                    onChange={(e) => handleInputChange(item.id, e.target.value)}
                  />
                  <button
                    className="btn btn-success"
                    onClick={() => handleEntregadoChange(item.id)}
                  >
                    Guardar
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableChequesFisicos;
