import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Asegúrate de instalar axios si aún no lo has hecho
import { parse, endOfMonth } from 'date-fns';

const TableCheques = ({ bancoActual, userId }) => {
  const [data, setData] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bancoCubierto, setBancoCubierto] = useState('');

  useEffect(() => {
    const fetchChequesData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.post('https://patron.com.ar:5000/api/chequesTable', { userId });
        const cheques = response.data;
        cheques.sort((a, b) => {
          const dateA = parse(a.fechaPago, 'dd-MM-yyyy', new Date());
          const dateB = parse(b.fechaPago, 'dd-MM-yyyy', new Date());
          return dateA - dateB;
        });
        setData(cheques);
      } catch (error) {
        setError(error);
        console.error('Error al obtener los datos de cheques:', error);
      } finally {
        setLoading(false);
      }
    };
  
    if (userId) {
      fetchChequesData();
    }
  }, [userId]);
  
  useEffect(() => {
    // Filtrar los cheques que solo tienen los estados relevantes
    const estadosRelevantes = ["Emitido", "Aceptado"];
    let acumulado = 0;
    let fechaCubierta = '';

    const chequesFiltrados = data.filter(item => estadosRelevantes.includes(item.estado));

    for (const item of chequesFiltrados) {
      const monto = Number(item.monto);
      acumulado += monto;

      if (acumulado >= bancoActual) {
        fechaCubierta = item.fechaPago;
        break;
      }
    }

    setBancoCubierto(fechaCubierta);

  }, [data, bancoActual, setBancoCubierto]);


  const handleEstadoChange = async (index, event) => {
    const newStatus = event.target.value;
    const updatedFilteredData = [...filteredData];
    const item = updatedFilteredData[index];
  
    // Encuentra el índice del elemento en la lista original `data`
    const originalIndex = data.findIndex((cheque) => cheque.id === item.id);
  
    if (originalIndex === -1) {
      console.error('Elemento no encontrado en la lista original');
      return;
    }
  
    // Actualiza el estado del elemento en la lista original `data`
    const updatedData = [...data];
    updatedData[originalIndex].estado = newStatus;
    setData(updatedData);
  
    try {
      await axios.patch(`https://patron.com.ar:5000/api/chequesTable/${userId}/Electronicos/Egresos/${item.id}`, { estado: newStatus });
      console.log('Estado actualizado correctamente');
    } catch (error) {
      console.error('Error al actualizar el estado en la base de datos:', error);
    }
  };
  
  

  const filteredData = data
  .filter(
    (item) =>
      (statusFilter === '' || item.estado === statusFilter) &&
      (item.numero.toString().includes(searchTerm) ||
        item.proveedor.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.cuit.toString().includes(searchTerm) ||
        item.fechaPago.includes(searchTerm) ||
        item.monto.toString().includes(searchTerm))
  );

  const totalMonto = filteredData.reduce((total, item) => total + Number(item.monto), 0);



  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  if (loading) return <p>Cargando datos...</p>;
  if (error) return <p>Error al cargar los datos: {error.message}</p>;

  return (
      <div>
          <div style={{ margin: "1%"}} >
                <h2>TABLA DE CHEQUES ELECTRONICOS</h2>
                <h2>Banco Cubierto Hasta {bancoCubierto}</h2>
          </div>
          <div className="mb-3">
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="form-control mb-3"
          />

          <select
      className="form-select me-2"
      onChange={handleStatusChange}
      value={statusFilter}
    >
      <option value="">Filtrar por Estado</option>
      <option value="Emitido">Emitido</option>
      <option value="Aceptado">Aceptado</option>
      <option value="Devolución Pendiente">Devolución Pendiente</option>
      <option value="Repudiado">Repudiado</option>
      <option value="Anulado">Anulado</option>
      <option value="Depósito en proceso">Depósito en proceso</option>
      <option value="Presentado al Cobro">Presentado al Cobro</option>
      <option value="Pagado">Pagado</option>
      <option value="Rechazado">Rechazado</option>
      <option value="Vencido">Vencido</option>
    </select>

          </div>

          <table className="table table-striped table-bordered table-hover">
      <thead>
        <tr>
          <th>Número</th>
          <th className="d-none d-md-table-cell">Proveedor</th>
          <th className="d-none d-md-table-cell">Cuit</th>
          <th id="tablePago"><span className="d-none d-md-inline">Fecha de</span> Pago</th>
          <th id="tableImporte">Importe</th>
          <th id="tableEstado">Estado</th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item, index) => (
          <tr key={index}>
            <td>{item.numero}</td>
            <td className="d-none d-md-table-cell">{item.proveedor}</td>
            <td className="d-none d-md-table-cell">{item.cuit}</td>
            <td>{item.fechaPago}</td>
            <td>{Number(item.monto).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</td>
            <td>
              <select
                className="form-select"
                value={item.estado}
                onChange={(e) => handleEstadoChange(index, e)}
              >
                <option value="Emitido">Emitido</option>
                <option value="Aceptado">Aceptado</option>
                <option value="Devolucion Pendiente">Devolución Pendiente</option>
                <option value="Repudiado">Repudiado</option>
                <option value="Anulado">Anulado</option>
                <option value="Depósito en proceso">Depósito en proceso</option>
                <option value="Presentado al Cobro">Presentado al Cobro</option>
                <option value="Pagado">Pagado</option>
                <option value="Rechazado">Rechazado</option>
                <option value="Vencido">Vencido</option>
              </select>
            </td>
          </tr>
        ))}
      </tbody>
    </table>


        <div className="mt-3">
          <strong>Total en Tabla: {totalMonto.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</strong>
        </div>
      
    </div>
  );
};

export default TableCheques;
