import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Trash, PencilSquare } from "react-bootstrap-icons";
import { db } from "../../firebaseConfig"; // Importamos la configuración de Firebase
import { collection, doc, setDoc, updateDoc, deleteDoc, getDocs } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-firestore.js"; 

const CostosFijos = () => {
  const [obligaciones, setObligaciones] = useState([]);
  const [form, setForm] = useState({ obligacion: "", tipo: "", costo: "", estado: "" });
  const [selectedObligacion, setSelectedObligacion] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false); // Controla la visibilidad del modal

  // Referencia al collection 'obligaciones' en Firestore
  const obligacionesRef = collection(db, "obligaciones");

  // Función para obtener las obligaciones desde Firestore
  const fetchObligaciones = async () => {
    try {
      const querySnapshot = await getDocs(obligacionesRef);
      const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      data.sort((a, b) => a.obligacion.localeCompare(b.obligacion)); // Ordena alfabéticamente
      setObligaciones(data);
    } catch (error) {
      console.error("Error al obtener las obligaciones: ", error);
    }
  };

  useEffect(() => {
    fetchObligaciones();
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setForm({ ...form, [id]: value });
  };

  const handleAddObligacion = async () => {
    if (!form.obligacion || !form.tipo || !form.costo || !form.estado) {
      Swal.fire("Por favor, completa todos los campos obligatorios.", "", "error");
      return;
    }

    try {
      const newObligacionRef = doc(obligacionesRef); // Crea un nuevo documento con un ID automático
      await setDoc(newObligacionRef, form); // Establece los datos de la nueva obligación en Firestore
      Swal.fire("Obligación agregada con éxito", "", "success");

      // Agregar la nueva obligación al estado y ordenar
      setObligaciones((prevObligaciones) => {
        const updatedObligaciones = [...prevObligaciones, { ...form }];
        updatedObligaciones.sort((a, b) => a.obligacion.localeCompare(b.obligacion));
        return updatedObligaciones;
      });

      setForm({ obligacion: "", tipo: "", costo: "", estado: "" }); // Limpia el formulario
      setShowModal(false); // Cierra el modal después de agregar
    } catch (error) {
      console.error("Error al agregar la obligación: ", error);
      Swal.fire("Hubo un error al agregar la obligación", "", "error");
    }
  };

  const handleUpdateObligacion = async () => {
    if (!selectedObligacion) return;

    try {
      const obligacionDocRef = doc(obligacionesRef, selectedObligacion.id); // Referencia al documento de la obligación a actualizar
      await updateDoc(obligacionDocRef, form); // Actualiza la obligación en Firestore
      Swal.fire("Obligación actualizada con éxito", "", "success");

      // Actualizar el estado con la obligación modificada y ordenar
      setObligaciones((prevObligaciones) => {
        const updatedObligaciones = prevObligaciones.map((obligacion) =>
          obligacion.id === selectedObligacion.id ? { ...form, id: selectedObligacion.id } : obligacion
        );
        updatedObligaciones.sort((a, b) => a.obligacion.localeCompare(b.obligacion)); // Ordena alfabéticamente
        return updatedObligaciones;
      });

      setSelectedObligacion(null);
      setForm({ obligacion: "", tipo: "", costo: "", estado: "" }); // Limpia el formulario
      setShowModal(false); // Cierra el modal después de actualizar
    } catch (error) {
      console.error("Error al actualizar la obligación: ", error);
      Swal.fire("Hubo un error al actualizar la obligación", "", "error");
    }
  };

  const handleDeleteObligacion = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esto",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "No, cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const obligacionDocRef = doc(obligacionesRef, id); // Referencia al documento a eliminar
          await deleteDoc(obligacionDocRef); // Elimina el documento de Firestore
          Swal.fire("Eliminado", "La obligación ha sido eliminada", "success");

          // Actualizar la lista de obligaciones
          fetchObligaciones(); 
        } catch (error) {
          console.error("Error al eliminar la obligación: ", error);
          Swal.fire("Hubo un error al eliminar la obligación", "", "error");
        }
      }
    });
  };

  const filteredObligaciones = obligaciones
    .filter((obligacion) =>
      obligacion.obligacion.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => a.obligacion.localeCompare(b.obligacion)); // Ordenar alfabéticamente

  return (
    <>
      <div style={{ textAlign: "center", margin: "20px 0" }}>
        <h2>Obligaciones Mensuales</h2>
      </div>
      <div className="container-fluid-prov">
        <div className="listObligaciones">
          <h3>Listado de Obligaciones</h3>
          <div className="d-flex justify-content-center mb-3">
            <input
              type="text"
              className="form-control w-80"
              placeholder="Buscar Obligación"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                setForm({ obligacion: "", tipo: "", costo: "", estado: "" }); // Limpia el formulario
                setShowModal(true); // Abre el modal en modo agregar
              }}
            >
              Agregar
            </button>
          </div>
          <div>
            <h4>Lista de Obligaciones</h4>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col" className="text-center">Obligación</th> {/* Centrado */}
                  <th scope="col" className="text-center d-none d-md-table-cell">Tipo</th> {/* Centrado */}
                  <th scope="col" className="text-center">Costo</th> {/* Centrado */}
                  <th scope="col" className="text-center">Estado</th> {/* Centrado */}
                  <th scope="col" className="text-center">Acciones</th> {/* Centrado */}
                </tr>
              </thead>
              <tbody>
                {filteredObligaciones.map((obligacion) => (
                  <tr key={obligacion.id}>
                    <td className="text-center">{obligacion.obligacion}</td> {/* Centrado */}
                    <td className="text-center d-none d-md-table-cell">{obligacion.tipo}</td> {/* Centrado */}
                    <td className="text-center">{Number(obligacion.costo).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</td> {/* Centrado */}
                    <td className="text-center">
                      <button
                        className={`btn ${obligacion.estado === "Pagado" ? "btn-success" : "btn-danger"} btn-sm`}
                        disabled
                      >
                        {obligacion.estado}
                      </button>
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-success btn-sm me-1"
                        onClick={() => handleEditObligacion(obligacion)}
                      >
                        <PencilSquare />
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDeleteObligacion(obligacion.id)}
                      >
                        <Trash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Mostrar el total de los costos fijos */}
            <h4 className="text-center">
              El total de los costos fijos es:{" "}
              <span>
                {Number(
                  obligaciones.reduce((total, obligacion) => total + parseFloat(obligacion.costo || 0), 0)
                ).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}
              </span>
            </h4>

            {/* Mostrar el aproximado a facturar para cubrir los costos */}
            <h4
              className="text-center"
              style={{
                backgroundColor: "green",
                color: "white",
                fontWeight: "bold",
                padding: "10px",
                borderRadius: "5px",
                marginTop: "20px",
              }}
            >
              El aproximado a facturar para cubrir los costos es de{" "}
              <span>
                {(() => {
                  // Calcular el total de los costos fijos
                  const totalCostos = obligaciones.reduce(
                    (total, obligacion) => total + parseFloat(obligacion.costo || 0),
                    0
                  );

                  // Calcular las ventas necesarias para cubrir los costos
                  const ventasNecesarias = (totalCostos * 100) / 30;

                  // Retornar el valor formateado como moneda
                  return ventasNecesarias.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' });
                })()}
              </span>
            </h4>
          </div>
        </div>
      </div>



      {/* Modal para agregar/editar obligación */}
      {showModal && (
        <div className="modal show" tabIndex="-1" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {selectedObligacion ? "Editar Obligación" : "Agregar Obligación"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)} // Cierra el modal
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="obligacion" className="form-label">
                    Obligación
                  </label>
                  <input
                    type="text"
                    id="obligacion"
                    className="form-control"
                    value={form.obligacion}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="tipo" className="form-label">
                    Tipo
                  </label>
                  <select
                    id="tipo"
                    className="form-control"
                    value={form.tipo}
                    onChange={handleInputChange}
                  >
                    <option value="">Elegir Tipo</option>
                    <option value="Servicio">Servicio</option>
                    <option value="Impuesto">Impuesto</option>
                    <option value="Salario">Salario</option>
                    <option value="Inmueble">Inmueble</option>
                    <option value="Otros">Otros...</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="costo" className="form-label">
                    Costo
                  </label>
                  <input
                    type="number"
                    id="costo"
                    className="form-control"
                    value={form.costo}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="estado" className="form-label">
                    Estado
                  </label>
                  <select
                    id="estado"
                    className="form-control"
                    value={form.estado}
                    onChange={handleInputChange}
                  >
                    <option value="">Elegir Estado</option>
                    <option value="Pendiente">Pendiente</option>
                    <option value="Pagado">Pagado</option>
                  </select>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={selectedObligacion ? handleUpdateObligacion : handleAddObligacion}
                >
                  {selectedObligacion ? "Actualizar" : "Agregar"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CostosFijos;
